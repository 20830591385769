<template>
	<div class="wrap">
		<van-list
			v-model="loading"
			:finished="finished"
			finished-text="没有更多了"
			@load="onLoad"
		>
			<!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
			<div class="item-box" v-for="(item, index) in list" :key="index">
				<span class="title">{{item.month}}</span>
				<div class="item" v-for="(ele, i) in item.list" :key="i">
					<div class="item-top">
						<div class="top-item">
							<div>提现申请</div>
							<div>{{(ele.cashOutFee + '').split('.')[0]}}<span v-if="(ele.cashOutFee + '').split('.')[1]">.{{(ele.cashOutFee + '').split('.')[1]}}</span></div>
						</div>
						<div class="top-item">
							<div>缴税</div>
							<div>{{(ele.tax + '').split('.')[0]}}<span v-if="(ele.tax + '').split('.')[1]">.{{(ele.tax + '').split('.')[1]}}</span></div>
						</div>
						<div class="top-item">
							<div>实际到账</div>
							<div>{{(ele.actualFee + '').split('.')[0]}}<span v-if="(ele.actualFee + '').split('.')[1]">.{{(ele.actualFee + '').split('.')[1]}}</span></div>
						</div>
					</div>
					<div class="item-bottom">
						<span>{{formatTime(ele.createdAt)}}</span>
						<span>{{getTextByStatus(ele.transfersStatus, ele.status)}}</span>
					</div>
					<div class="item-bottom" v-if="ele.reason">
						<span class="reason">审核原因：{{ele.reason}}</span>
					</div>
				</div>
			</div>
		</van-list>
	</div>
</template>
<script>
import { doctorWithdrawList } from '@/request/api/doctor-withdraw';
import { dateFormat } from '@/utils/time';
export default {
	data () {
		return {
			list: [],
			page: 1,
			limit: 10,
			finished: false,
			loading: false
		};
	},
	created () {
		// this.getList();
	},
	methods: {
		getTextByStatus (status1, status2) {
			const ret1 = ['审核中', '审核中', '提现成功', '提现失败'];
			const ret2 = ['待处理', '审核同意', '审核拒绝'];
			if (status2 === 1) return ret1[status1];
			return ret2[status2];
		},
		formatMonthTime (time) {
			return dateFormat(new Date(time), 'yyyy年MM月');
		},
		formatTime (time) {
			return dateFormat(new Date(time), 'yyyy年MM月dd日 hh:mm:ss');
		},
		onLoad () {
			this.loading = true;
			doctorWithdrawList({
				page: this.page,
				limit: this.limit
			}).then(data => {
				if (data && data === 'retry') {
					this.loading = true;
					this.onLoad();
				} else if (data) {
					this.list = [...this.list, ...data.rows];
					const array = [];
					const json = {};
					for (const item of this.list) {
						if (!json[this.formatMonthTime(item.createdAt)]) json[this.formatMonthTime(item.createdAt)] = [];
						json[this.formatMonthTime(item.createdAt)].push(item);
					}
					for (const key in json) {
						array.push({
							month: key,
							list: json[key]
						});
					}
					this.list = [...array];
					if (this.page === +data.pageInfo.totalPage || data.rows.length === 0) {
						this.finished = true;
					} else {
						this.loading = false;
						this.page++;
					}
					// console.log('data===', data);
				}
			});
		},
		getList () {
		}
	}
};
</script>
<style lang="less" scoped>
	.wrap {
		padding-bottom: 12px;
		.item-box {
			display: flex;
			flex-direction: column;
			.title {
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #8B8B8B;
				line-height: 13px;
				margin-top: 16px;
			}
			.item {
				background: #FFFFFF;
				box-shadow: 0px 5px 18px -4px rgba(16,31,46,0.04);
				border-radius: 4px;
				display: flex;
				flex-direction: column;
				margin-top: 12px;
				padding: 20px 0;
				.item-top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.top-item {
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						div:first-child {
							font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #959595;
							line-height: 12px;
						}
						div:last-child {
							font-size: 22px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
							color: #3B3D40;
							line-height: 22px;
							margin-top: 8px;
							span {
								font-size: 13px;
								margin-top: 8px;
							}
						}
					}
				}
				.item-bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 13px;
					padding: 0 20px;
					margin-top: 16px;
					.reason {
						word-break: break-all;
					}
				}
			}
		}
	}
</style>