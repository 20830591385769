var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "item-box" },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(item.month)),
              ]),
              _vm._l(item.list, function (ele, i) {
                return _c("div", { key: i, staticClass: "item" }, [
                  _c("div", { staticClass: "item-top" }, [
                    _c("div", { staticClass: "top-item" }, [
                      _c("div", [_vm._v("提现申请")]),
                      _c("div", [
                        _vm._v(_vm._s((ele.cashOutFee + "").split(".")[0])),
                        (ele.cashOutFee + "").split(".")[1]
                          ? _c("span", [
                              _vm._v(
                                "." +
                                  _vm._s((ele.cashOutFee + "").split(".")[1])
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "top-item" }, [
                      _c("div", [_vm._v("缴税")]),
                      _c("div", [
                        _vm._v(_vm._s((ele.tax + "").split(".")[0])),
                        (ele.tax + "").split(".")[1]
                          ? _c("span", [
                              _vm._v(
                                "." + _vm._s((ele.tax + "").split(".")[1])
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "top-item" }, [
                      _c("div", [_vm._v("实际到账")]),
                      _c("div", [
                        _vm._v(_vm._s((ele.actualFee + "").split(".")[0])),
                        (ele.actualFee + "").split(".")[1]
                          ? _c("span", [
                              _vm._v(
                                "." + _vm._s((ele.actualFee + "").split(".")[1])
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-bottom" }, [
                    _c("span", [_vm._v(_vm._s(_vm.formatTime(ele.createdAt)))]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getTextByStatus(ele.transfersStatus, ele.status)
                        )
                      ),
                    ]),
                  ]),
                  ele.reason
                    ? _c("div", { staticClass: "item-bottom" }, [
                        _c("span", { staticClass: "reason" }, [
                          _vm._v("审核原因：" + _vm._s(ele.reason)),
                        ]),
                      ])
                    : _vm._e(),
                ])
              }),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }